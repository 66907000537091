<template>
  <div class="coin c-content">
    <nav-bar :title="$t('xinzeng.chongbi')">
      <van-icon name="wap-home-o" slot="right" />
    </nav-bar>
    <div class="choicetoken">
      <span class="choice_token" @click="show = true">
        {{ $t("xinzeng.xuanzebi") }}:
        <input
          class="choice_input"
          disabled
          v-model="data"
          :placeholder="data"
          type="text"
        />
        <img src="../../../assets/imgs/my/xiaka.png" alt />
      </span>

      <div class="token_list">
        <!-- // 选择币 -->
        <div
          :class="item.id == id ? 'list_box2' : 'list_box'"
          v-for="item in user_token_set"
          :key="item.index"
          @click="choice(item)"
        >
          <img :src="item.icon" alt />
          <span>{{ item.token }}</span>
        </div>
      </div>
    </div>
    <div class="coin_cen">
      <div class="coin_title">
        <div class="dizhi">
          <span class="dizhi_span"
            >{{ $t("recharge.one") }}: {{ address }}</span
          >
        </div>
        <div>
          <span>
            {{ $t("recharge.min") }}:
            <i class="site">{{ deposit_min }}&nbsp;{{ data }}</i>
          </span>
        </div>
        <div class="code">
          <div id="qrcode" ref="qrcode"></div>
        </div>
        <div class="btn">
          <van-button
            class="btn1"
            :data-clipboard-text="address"
            @click="copy"
            size="large"
            >{{ $t("recharge.fuzhi") }}</van-button
          >
        </div>
      </div>
    </div>
    <van-popup v-model="show" class="addwaltshow_box2" position="bottom">
      <span
        class="box_makewalt"
        @click="choicetoken(item)"
        v-for="item in tokenSet"
        :key="item.id"
      >
        <img :src="item.icon" alt />
        <span>{{ item.token }}</span>
      </span>
      <!-- <span class="box_makewalt box_addwalt"></span> -->
      <span class="line"></span>
      <span class="box_makewalt quxiao" @click="show = false">取消</span>
    </van-popup>
  </div>
</template>

<script>
// 复制地址插件
import Clipboard from "clipboard";
// 地址转换二维码插件
import QRCode from "qrcodejs2";

export default {
  name: "coin",
  // components: { Loading },
  data() {
    return {
      show: false,
      data: "NULS",
      Site: "",
      address: "",
      deposit_min: 0,
      user_token_set: [],

      flag: true,
      tokenSet: [], // 货币列表
      id: "",
      actions: [{ name: "选项一" }, { name: "选项二" }, { name: "选项三" }],
    };
  },
  created() {
    this.getTokenSet();
    this.site();
  },
  methods: {
    choicetoken(e) {
      console.log(e.token);
      this.data = e.token;
      this.site();
      this.id = e.id;
      this.show = false;
    },
    choice(e) {
      this.data = e.token;
      this.site();
      this.id = e.id;
    },
    // 获取数据
    getTokenSet() {
      this.$post2("user/api/index/userAsset", {})
        .then((res) => {
          console.log(res);
          this.tokenSet = res.token_set;
          this.user_token_set = res.user_token_set;
          this.id = res.token_set[0].id;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // 复制
    copy() {
      var clipboard = new Clipboard(".btn1");
      clipboard.on("success", (e) => {
        this.$dialog.alert({
          message: this.$t("xinzeng.fuzhichengg"),
        });
        // 释放内存
        clipboard.destroy();
      });
    },
    qrcode(address) {
      // console.log(address)
      new QRCode("qrcode", {
        width: 150, // 设置宽度，单位像素
        height: 150, // 设置高度，单位像素
        text: address, // 设置二维码内容或跳转地址
      });
    },
    // 获取地址
    site() {
      this.$post2("Pay/Api/Index/getDepositAddress", {
        token: this.data,
        mainchain: "TRX",
      })
        .then((res) => {
          document.getElementById("qrcode").innerHTML = "";
          this.address = res.address;
          this.deposit_min = res.deposit_min;
          this.$nextTick(() => {
            this.qrcode(res.address);
          });
        })
        .catch((err) => {
          this.$dialog.alert({
            message: this.$t("xinzeng.buzhichi"),
          });
        });
    },
  },
};
</script>

<style scoped lang='less'>
.addwaltshow_box2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  // height: 4.5rem;
  border-radius: 0.1rem 0.1rem 0 0;
  border: none;
  .box_makewalt {
    height: 1.1rem;
    // text-align: center;
    // line-height: 1.1rem;
    color: #5056fd;
    font-size: 0.32rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 24px;
      height: 24px;
      margin-left: 0.4rem;
    }
    span {
      margin-left: 0.2rem;
    }
  }
  .box_addwalt {
    border-bottom: none;
  }
  .line {
    height: 0.1rem;
    background: #f6f6f8;
  }
  .quxiao {
    border-bottom: none;
    color: #999999;
  }
}
.choice_input {
  width: 1.2rem;
  margin: 0 auto;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  border: none;
  margin-left: 0.1rem;
  background: #ffffff;
}
.choicetoken {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  img {
    width: 12px;
    height: 12px;
  }
  .choice_token {
    width: 84%;
    margin: 0 auto;
    margin-top: 0.2rem;
  }
  .token_list {
    margin: 3px auto;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-around;
    width: 93%;

    .list_box {
      // width: 28%;
      width: 2rem;
      height: 1rem;
      line-height: 1rem;
      border: 1px solid #c6cbd4;
      border-radius: 5px;
      font-size: 0.3rem;
      display: flex;
      img {
        width: 0.5rem;
        height: 0.5rem;
        margin-top: 0.2rem;
        margin-left: 6px;
      }
      span {
        margin-left: 4px;
        font-weight: 600;
      }
    }
    .list_box2 {
      width: 2rem;
      height: 1rem;
      line-height: 1rem;
      border: 1px solid #4282f9;
      border-radius: 5px;
      font-size: 0.3rem;
      display: flex;
      img {
        width: 0.5rem;
        height: 0.5rem;
        margin-top: 0.2rem;
        margin-left: 6px;
      }
      span {
        margin-left: 4px;
        font-weight: 600;
      }
    }
  }
}
.dizhi {
  height: 80px !important;
  .dizhi_span {
    width: 100% !important;
    height: 80px !important;
    word-wrap: break-word;
    word-break: normal;
    line-height: 26px !important;
  }
}
.coin {
  width: 100%;
  height: 100%;
  font-size: 18px;
  .coin_cen {
    width: 100%;
    height: 100%;
    // padding-top: 10px;
    .coin_title {
      > div {
        height: 50px;
        display: flex;
        padding: 0 30px;
        span {
          display: block;
          height: 40px;
          line-height: 40px;
          // overflow: hidden;
          // text-overflow: ellipsis;
          // white-space: nowrap;
        }
        .avater {
          border-radius: 50%;
          width: 40px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .code {
        width: 100%;
        height: 300px;
        border-top: 1px dashed #ddd;
        padding: 0;
        position: relative;
      }
      .btn {
        display: block;
        margin: 0;
        .btn1,
        .btn2 {
          margin-top: 10px;
          border: none;
          text-align: center;
          border-radius: 10px;
          color: white;
          background: #00c0ed;
        }
      }
    }
  }
}
.c-nav-bar {
  span,
  i {
    font-size: 24px !important;
  }
}
</style>
<style>
#qrcode {
  width: 150px;
  height: 150px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}
#qrcode > img {
  width: 100%;
  height: 100%;
}
</style>



