import { render, staticRenderFns } from "./Coin.vue?vue&type=template&id=1ee69558&scoped=true&"
import script from "./Coin.vue?vue&type=script&lang=js&"
export * from "./Coin.vue?vue&type=script&lang=js&"
import style0 from "./Coin.vue?vue&type=style&index=0&id=1ee69558&scoped=true&lang=less&"
import style1 from "./Coin.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ee69558",
  null
  
)

export default component.exports